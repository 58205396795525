import React from "react";
import speakers from "../images/3people.svg"
// import speaker4 from "../images/4people.svg"

export default function Landing() {
  return (
    <div id="landing" className="bg-white py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center">
          {/* Text Column */}
          <div className="w-full md:w-1/2 mb-8 md:mb-0 text-center md:text-left">
            <h1 className="text-6xl text-black mb-4">
              Learn <span className="font-bold">Public Speaking</span> and{" "}
              <span className="font-bold">Debating</span>
            </h1>
            <p className="text-black text-lg mb-8">
              For ages 10-18, acquire the communication skills <br /> to become
              future leaders
            </p>
            <div className="">
              <button className="bg-blue-700 text-white px-6 py-2 rounded-md text-lg font-semibold hover:bg-blue-800">
                <a
                  href="https://forms.gle/iEx4WL3ZM4h5FHiD6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Book a free class now!
                </a>
              </button>
            </div>
          </div>
          {/* Image Column */}
          <div className="w-full md:w-1/2">
            <img src={speakers} alt="Banner" className="w-full rounded-lg" />
          </div>
        </div>
      </div>
    </div>
  );
};
