import Navbar from "../components/Navbar"
import Landing from "../components/Landing"
import Features from "../components/WhyChoose";
import Footer from "../components/Footer";
import Program from "../components/Program";
import FAQ from "../components/FAQ";
import CTA from "../components/CTA";
import Instructors from "../components/Instructors";
import NewsletterForm from "../components/Newsletter";

export default function Home() {
  return (
    <div>
      <Navbar />
      <Landing />
      <Features />
      <div className="mt-10"></div>
      <Instructors />
      <div className="mt-10"></div>
      <Program />
      <CTA />
      <div className="mt-10"></div>
      <FAQ />
      <NewsletterForm />
      <Footer />
    </div>
  );  
}