import React from "react";

const Footer = () => {
  return (
    <footer className="bg-blue-600 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center">
          {/* Row 1 */}
          <div className="mb-4">
            <h3 className="text-lg">© SpeakersHub 2024</h3>
            {/* <p className="text-sm">123 Street Name, City, Country</p> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
