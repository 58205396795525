import React from "react";
import globe from "../images/globe.svg"
import storybook from "../images/storybook.svg"
import graph from "../images/graph.svg";


const Features = () => {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl text-center mb-8 font-lato">
          Why <span className="font-bold">choose</span> us
        </h2>
        <div className="flex flex-col md:flex-row justify-center items-center mt-16">
          {/* Feature 1 */}
          <div className="flex flex-col items-center w-2/3 md:w-1/3">
            <img
              src={globe}
              alt="Feature 1"
              className="h-32 mb-4 "
            />
            <h3 className="text-xl font-semibold font-lato mb-2">Global Perspective</h3>
            <p className="text-gray-700 text-center md:w-2/3">
              Get a better understanding of the world around us through exploring topics such as Economics, Geopolitics, History and more
            </p>
          </div>
          {/* Feature 2 */}
          <div className="flex flex-col items-center w-2/3 md:w-1/3 mt-8 md:mt-0">
            <img
              src={storybook}
              alt="Feature 2"
              className="h-32 mb-4 "
            />
            <h3 className="text-xl font-semibold mb-2 font-lato">Become a Storyteller</h3>
            <p className="text-gray-700 text-center md:w-2/3">
              Develop the lifelong skill of communicating better and use it to pitch your ideas, sell yourself, negotiate, network and interview
            </p>
          </div>
          {/* Feature 3 */}
          <div className="flex flex-col items-center w-2/3 md:w-1/3 mt-8 md:mt-0">
            <img
              src={graph}
              alt="Feature 3"
              className="h-32 mb-4 "
            />
            <h3 className="text-xl font-semibold mb-2 font-lato">Community</h3>
            <p className="text-gray-700 text-center md:w-2/3">
              Attend national and international tournaments, meet like-minded people from around the world and make new friends
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
