import React, { useState } from "react";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-300 py-4">
      <button
        className="w-full text-left flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-semibold">{question}</span>
        <svg
          className={`w-6 h-6 transform transition-transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
    </div>
  );
};

const FAQ = () => {
  const faqs = [
    {
      question:
        "Why should I enrol my child for public speaking/debating classes?",
      answer:
        "In a world increasingly becoming more easier to navigate with technology and AI, what sets you apart is your ability to communicate well with people. Through our program your child gets to become a better communicator and in the process learn a bit better about the complex systems that surround us through exploring topics not taught in school.",
    },
    {
      question: "Will classes be online or in-person?",
      answer:
        "All classes will be held virtually.",
    },
    {
      question: "How much will the program cost?",
      answer:
        "The fees are $150 per month. If you require financial assistance we can be accomodating, simply let us know.",
    },
    // Add more FAQs as needed
  ];

  return (
    <div id="faq" className="border mb-12 w-2/3 mx-auto md:px-24 px-10 py-8 shadow-lg">
      <h2 className="text-2xl md:text-3xl font-bold mb-6">Frequently Asked Questions</h2>
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FAQ;
