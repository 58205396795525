import React from "react";
// import Savannah from "../images/savannahlogo.jpeg";
import Book from "../images/book.png";
import Calendar from "../images/calendar.png";
import Graduate from "../images/graduate.png";

export default function Program() {
  return (
    <div id="program" className="bg-blue-100 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-lato text-center mb-8">
          About the <span className="font-bold">Program</span>
        </h2>
        <div className="flex flex-col md:flex-row justify-center items-center mt-16">
          {/* Feature 1 */}
          <div className="flex flex-col items-center w-full md:w-1/3">
            <img src={Book} alt="Feature 1" className="w-16 h-16 mb-4 " />
            <h3 className="text-xl font-inter mb-2">2 hours per week</h3>
            {/* <p className="text-gray-700 text-center">
              Description of feature 1 goes here.
            </p> */}
          </div>
          {/* Feature 2 */}
          <div className="flex flex-col items-center w-full md:w-1/3 mt-8 md:mt-0">
            <img src={Calendar} alt="Feature 2" className="w-16 h-16 mb-4 " />
            <h3 className="text-xl mb-2 font-inter">
              16 classes every term
            </h3>
            {/* <p className="text-gray-700 text-center">
              Description of feature 2 goes here.
            </p> */}
          </div>
          {/* Feature 3 */}
          <div className="flex flex-col items-center w-full md:w-1/3 mt-8 md:mt-0">
            <img src={Graduate} alt="Feature 3" className="w-216 h-16 mb-4 " />
            <h3 className="text-xl mb-2 font-inter">
              10 students each class
            </h3>
            {/* <p className="text-gray-700 text-center">
              Description of feature 3 goes here.
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};
