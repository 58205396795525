// import logo from './logo.svg';
import './App.css';
import {Routes, Route} from "react-router-dom"
import Home from './pages/Home';
import Blog from './pages/Blog'
import BlogPost from './pages/BlogPost';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} exact />
      <Route path="/blog" element={<Blog />} exact />
      <Route path="/blog/:id" element={<BlogPost />} exact />
    </Routes>
  );
}

export default App;
