import React from "react";
import inqiyad from "../images/inqiyad.png"
import jumanah from "../images/jumanah.png"

export default function Instructors () {
  return (
    <div id="instructors" className="container mx-auto p-4">
      <h1 className="text-4xl mb-4 font-lato text-center md:text-left">
        Meet your <span className="font-bold">instructors</span>
      </h1>
      <div className="flex flex-col">
        <div className="w-full md:w-2/3 flex flex-col md:flex-row">
          <div className="py-4 w-1/3 md:w-1/4 mx-auto">
            <img src={inqiyad} alt="inqiyad" className="md:w-full" />
          </div>
          <div className="py-4 px-4 w-2/3 mx-auto text-center md:text-left">
            <h2 className="font-lato font-semibold text-3xl md:text-2xl">
              Inqiyad Patwary
            </h2>
            <p className="">
              Inqiyad has 9 years of global debating experience, having spoken
              and won at tournaments around the world. His debating career has
              taken him to the USA, Thailand, Vietnam, India and Bangladesh to
              participate in international tournaments. He currently is a 4th
              year Computer Science student at the University of Waterloo and is
              a software engineer by trade. He is an ardent traveler, reader and
              learner. He is very passionate about teaching the next generation
              on topics such as geopolitics, philosophy, economics, history and
              politics. Tell him Hi at{" "}
              <a href="mailto:inqiyad@speakershub.org" className="font-bold">
                inqiyad@speakershub.org
              </a>
            </p>
          </div>
        </div>
        <div className="w-full md:w-2/3 flex flex-col md:flex-row">
          <div className="py-4 w-1/3 md:w-1/4 mx-auto">
            <img src={jumanah} alt="inqiyad" className="md:w-full" />
          </div>
          <div className="py-4 px-4 w-2/3 mx-auto text-center md:text-left">
            <h2 className="font-lato font-semibold text-3xl md:text-2xl">
              Jumanah Ahmed
            </h2>
            <p className="">
              Jumanah is a recent graduate of the University of Waterloo and has
              been debating since early years of high school. Her debating
              career has been based in both the Asian circuit and the North
              American circuit, allowing her to compete across the world in
              countries such as Bangladesh, Canada, USA, Vietnam, Spain and
              Malaysia. She is passionate about topics involving philosophy,
              economics and politics, along with supporting young debaters with
              the tools needed to excel in public speaking and competitive
              debating. Say Hi at{" "}
              <a href="mailto:jumanah@speakershub.org" className="font-bold">
                jumanah@speakershub.org
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
