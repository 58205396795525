 const ArticleNames = [
   {
     id: 1,
     title: "5 tips to become a better speaker",
     subtitle: "Easily learn to overcome your communication hurdles",
     image:
       "https://media.licdn.com/dms/image/C5112AQGeik6Y1zXVHA/article-cover_image-shrink_720_1280/0/1578255558502?e=2147483647&v=beta&t=BaHsDc0RDhja7AfDN-9jx7dyYyQAElPd3_0Kk7WFdtQ",
   },
   {
     id: 2,
     title: "3 reasons why you should learn public speaking",
     subtitle:
       "Effective communication skills is the differentiator to make you stand out",
     image:
       "https://img.freepik.com/free-vector/conference-stage-flat-concept-with-woman-speaking-front-audience-vector-illustration_1284-81444.jpg",
   },
 ];

export default ArticleNames