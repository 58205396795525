export default function CTA() {
  return (
    <div className="container mx-auto px-4 py-12 mt-16">
      <h2 className="text-4xl font-lato text-center font-bold mb-4">
        Ready to learn?
      </h2>
      <div className="text-center">
        <button className="bg-blue-700 text-white px-6 py-2 rounded-md text-lg font-inter font-semibold hover:bg-blue-800">
          <a
            href="https://forms.gle/iEx4WL3ZM4h5FHiD6"
            target="_blank"
            rel="noopener noreferrer"
          >
            Book a free class now!
          </a>
        </button>
      </div>
    </div>
  );
}
