import React, { useState } from "react";
import miclogo from "../images/miclogo.png";
import phone from "../images/phone.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white p-4 flex justify-between items-center border-b">
      <div className="flex items-center">
        {/* Logo */}
        <Link to="/">
          <div className="flex items-center mr-20">
            <img src={miclogo} alt="Logo" className="mr-2 h-10" />
            <span className="font-semibold text-xl font-lato">
              Speakers Hub
            </span>
          </div>
        </Link>
        {/* Links aligned to the left */}
        <div className="hidden md:block">
          <ul className="flex items-center space-x-8">
            <li>
              <HashLink to="/#program">
                <div className="text-lg font-inter font-medium">Program</div>
              </HashLink>
            </li>
            <li>
              <HashLink to="/#instructors">
                <div className="text-lg font-inter font-medium">
                  Instructors
                </div>
              </HashLink>
            </li>
            <li>
              <HashLink to="/#faq">
                <div className="text-lg font-inter font-medium">FAQ</div>
              </HashLink>
            </li>
            <li>
              <Link to="/blog ">
                <div className="text-lg font-inter font-medium">Blog</div>
              </Link>
            </li>
            <li>
              <HashLink to="/#contact">
                <div className="text-lg font-inter font-medium">Contact</div>
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="md:hidden flex items-center">
        <button onClick={toggleMenu} className="text-black focus:outline-none">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>
      <div
        className={`transition-all duration-300 ease-in-out transform md:hidden bg-white w-full absolute top-16 left-0 overflow-hidden ${
          isOpen ? "max-h-screen" : "max-h-0"
        }`}
      >
        <HashLink to="/#program">
          <div className="text-black block p-4 w-full text-center border-b">
            Program
          </div>
        </HashLink>
        <HashLink to="/#instructors">
          <div className="text-black block p-4 w-full text-center border-b">
            Instructors
          </div>
        </HashLink>
        <HashLink to="/#faq">
          <div className="text-black block p-4 w-full text-center border-b">
            FAQ
          </div>
        </HashLink>
        <HashLink to="/blog">
          <div className="text-black block p-4 w-full text-center border-b">
            Blog
          </div>
        </HashLink>
        <HashLink to="/#contact">
          <div className="text-black block p-4 w-full text-center border-b">
            Contact
          </div>
        </HashLink>
      </div>
      <div className="hidden md:block">
        {/* Links aligned to the right */}
        <ul className="flex items-center space-x-8">
          <li>
            <a
              href="https://forms.gle/iEx4WL3ZM4h5FHiD6"
              target="_blank"
              rel="noopener noreferrer"
              className="text-lg font-inter"
            >
              Book a Free Class
            </a>
          </li>
          <li>
            <a href="tel:+15489947619" className="flex items-center mr-20">
              <img src={phone} alt="Phone" className="mr-2 h-6" />
              <span className="text-lg font-inter ">+1 (548) 994 7619</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
