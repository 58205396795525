import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import ArticleNames from "../articles/ArticleNames";

console.log(ArticleNames);

export default function Blog() {
  return (
    <div>
      <Navbar />
      <div className="container mx-auto p-4 py-20">
        {/* Top Article */}
        <div className="flex flex-col md:flex-row mb-8">
          <img
            src={ArticleNames[0].image}
            alt={ArticleNames[0].title}
            className="w-full md:w-2/3 h-auto"
          />
          <div className="md:ml-8 flex items-center justify-left">
            <Link to={`/blog/${ArticleNames[0].title.replace(/ /g, "-")}`}>
              <h2 className="text-2xl font-bold mt-2 md:mt-0">
                {ArticleNames[0].title}
              </h2>
              <h3 className="text-lg mb-4">{ArticleNames[0].subtitle}</h3>
            </Link>
          </div>
        </div>

        {/* Other Articles */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {ArticleNames.slice(1).map((article) => (
            <Link to={`/blog/${article.title.replace(/ /g, "-")}`}>
              <div key={article.id} className="py-4 pr-8">
                <img
                  src={article.image}
                  alt={article.title}
                  className="w-full h-auto"
                />
                <h3 className="text-2xl font-semibold mt-2">{article.title}</h3>
                <h4 className="text-lg mb-4">{article.subtitle}</h4>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
