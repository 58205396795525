// src/components/BlogPost.js

import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const BlogPost = () => {
  const { id } = useParams();
  const [content, setContent] = useState({
    title: "",
    subtitle: "",
    image: "",
    body: "",
  });

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const article = await import(`../articles/${id}.md`);
        const response = await fetch(article.default);
        await response.text().then((text) => {
          const lines = text.split("\n");
          const title = lines[0].replace("# ", "");
          const subtitle = lines[2].replace("## ", "");
          const image = lines[4].match(/\((.*?)\)/)[1];
          const body = lines.slice(6).join("\n");
          setContent({ title, subtitle, image, body });
        })
      } catch (error) {
        console.error("Error fetching the Markdown file:", error);
      }
    };

    fetchMarkdown();
  }, [id]);

  return (
    <div>
      <Navbar />
      <div className="flex justify-center py-20 px-8">
        <div className="prose w-full max-w-2xl mx-auto text-left">
          <div className="text-left">
            <h1 className="text-3xl font-bold mb-2">{content.title}</h1>
            <h2 className="text-xl mb-4">{content.subtitle}</h2>
            <img
              src={content.image}
              alt={content.title}
              className="w-full mb-4 py-4"
            />
          </div>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {content.body}
          </ReactMarkdown>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogPost;
