import React, { useState } from "react";

const NewsletterForm = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const API_KEY = process.env.REACT_APP_AIRTABLE_API;
  const BASE_ID = process.env.REACT_APP_AIRTABLE_BASEID;
  const TABLE_NAME = "Newsletter"

  const handleSubmit = async(e) => {
    e.preventDefault();
    const formData = {
        fields: {
            "Emails": email
        }
    }
    setSubmitted(true);
    return await fetch(`https://api.airtable.com/v0/${BASE_ID}/${TABLE_NAME}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${API_KEY}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div id="contact" className="mb-12 mx-auto w-2/3 py-8">
      <h2 className="text-4xl font-bold mb-6">Did we miss anything?</h2>
      <p className="text-lg mb-6">
        For any queries, reach out to us at <a href="mailto:hello@speakershub.org" className="font-bold">hello@speakershub.org</a> or call us at{" "}
        <a href="tel:+15489947619" className="font-bold">
          (548)-994-7619
        </a>{" "}
      </p>
      {submitted ? (
        <p className="text-green-600">Thank you for signing up!</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-4 flex flex-col md:flex-row">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="shadow appearance-none border rounded md:w-2/6 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Sign up for our newsletter!"
            />
            <button
              type="submit"
              className="bg-blue-500 mt-4 md:mt-0 w-2/3 md:w-40 md:ml-4 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default NewsletterForm;
